<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'App',
  mounted() {
  }
}
</script>

<style lang="less">
@import "assets/style/reset.css";


.weui-dialog {
  .weui-dialog__hd {
    text-align: center;
    color: #1D2129;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
  }

  .weui-dialog__bd {
    color: #4E5969;
  }

  .weui-dialog__ft {
    .weui-dialog__btn_default {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #1D2129;
    }

    .weui-dialog__btn_primary {
      color: #1B5AF6;
    }
  }
}

.alert-face-detect {
  .weui-dialog {
    background-image: url("https://img.linkzo.cn/linkzoImages/miniprogram/face-error-tips-bg.png");
    background-size: 100% 135px;
    background-position: top left;
    background-repeat: no-repeat;
    padding-top: 135px;

    .weui-dialog__hd {
      padding-top: 12px;
      padding-bottom: 8px;
      text-align: center;
    }
  }
}


</style>