import Vue from 'vue';
import Router from '@/router'
import App from '@/App.vue';
import Store from "@/store";
import '@/utils/px2rem';
import wx from 'weixin-js-sdk';
import 'weui';
import weui from 'weui.js';

Vue.config.productionTip = false;
Vue.prototype.WX = wx;
Vue.prototype.$weui = weui;

async function getLoginInfoAndInit() {
    const res = await Store.dispatch('user/checkLoginAndGetLoginInfo');
    if (res) {
        new Vue({
            router: Router,
            store: Store,
            render: h => h(App),
        }).$mount('#app');
    }
}

getLoginInfoAndInit();