import Vue from 'vue';
import VueRouter from 'vue-router';
import Store from "@/store";
import path from "@/config/path";

Vue.use(VueRouter);

const router = new VueRouter({
    mode: 'history',
    routes: [
        {
            path: '/test',
            meta: {
                title: '枢智首页',
                needLogin: true,
            },
            name: 'testPage',
            component: () => import('@/pages/test/test.vue')
        },
        {
            path: '/login',
            meta: {
                title: '登录',
            },
            name: 'loginPage',
            component: () => import('@/pages/login/index.vue')
        },
        {
            path: '/faceDetect',
            meta: {
                title: '人脸录入',
            },
            name: 'faceDetect',
            component: () => import('@/pages/faceDetect/faceDetect')
        },
        {
            path: '/previewFile',
            meta: {
                title: '预览',
            },
            name: 'previewFile',
            component: () => import('@/pages/previewFile/previewFile')
        },
        {
            path: '/authHelper',
            meta: {
                title: '企业微信授权指引',
            },
            name: 'authHelper',
            component: () => import('@/pages/helpCenter/authorized')
        },
        {
            path: '*', redirect: '/test'
        }
    ]
});

router.beforeEach((to, from, next) => {
    // console.log(to, from)
    document.title = to.meta && to.meta.title || '枢智';
    if (to.meta.needLogin) {
        let userInfo = Store.state.user;
        if (!userInfo) {
            next(`${path.loginPage}?redirect=${encodeURIComponent(to.fullPath)}`);
        } else {
            next();
        }
    } else {
        next();
    }
});

export default router;